import React, { useEffect } from "react"
import gsap from "gsap"

const LazerLogo = ({className, style}) => {

  const gsapLazerAnimate = () => {
	let tl = gsap.timeline({repeat: -1});
    gsap.set('#LMaskPath', {attr: {'stroke-dasharray': 245, 'stroke-dashoffset': 735}})
    gsap.set('#AMaskPath', {attr: {'stroke-dasharray': 228, 'stroke-dashoffset': 228}})
    gsap.set('#ZMaskPath', {attr: {'stroke-dasharray': 280, 'stroke-dashoffset': 840}})
	gsap.set('#ERMaskPath', {attr: {'stroke-dasharray': 470, 'stroke-dashoffset': 1410}})
	
    tl.to('#LMaskPath', {attr: {'stroke-dashoffset': 490}, duration: 0.5, ease: "expo.out"})
    tl.to('#AMaskPath', {attr: {'stroke-dashoffset': 456}, duration: 0.5, ease: "expo.out", delay: -0.3})
    tl.to('#ZMaskPath', {attr: {'stroke-dashoffset': 560}, duration: 0.5, ease: "expo.out", delay: -0.3})
	tl.to('#ERMaskPath', {attr: {'stroke-dashoffset': 940}, duration: 0.5, ease: "expo.out", delay: -0.3})
	
    tl.to('#LMaskPath', {attr: {'stroke-dashoffset': 245}, duration: 0.5, ease: "expo.in"})
    tl.to('#AMaskPath', {attr: {'stroke-dashoffset': 684}, duration: 0.5, ease: "expo.in", delay: -0.3})
    tl.to('#ZMaskPath', {attr: {'stroke-dashoffset': 280}, duration: 0.5, ease: "expo.in", delay: -0.3})
    tl.to('#ERMaskPath', {attr: {'stroke-dashoffset': 470}, duration: 0.5, ease: "expo.in", delay: -0.3})
  }
  useEffect(() => {
    gsapLazerAnimate()
  }, [])

	return (
		<svg id="Lazer" xmlns="http://www.w3.org/2000/svg" viewBox="-34 320.588 543 151.412" style={style}>
			<g id="LazerAGroup" mask="url(#AMask)">
				<linearGradient id="Lazer_21_" gradientUnits="userSpaceOnUse" x1="-31.3879" y1="420.6117" x2="509.3312" y2="420.6117">
					<stop  offset="0" style={{stopColor:'#4E7FFF'}}/>
					<stop  offset="1" style={{stopColor:'#EC0260'}}/>
				</linearGradient>
				<path id="Lazer_10_" fill="url(#Lazer_21_)" d="M238.27,458.193c0.055-7.559-6.057-13.723-13.616-13.723H203.25h-7.075
					c-3.889,0-7.618-1.545-10.368-4.295l-19.76-19.76v-3.474c0-21.528-14.333-39.763-33.955-45.686
					c-0.008-0.002-0.016-0.004-0.024-0.007c-0.91-0.274-1.833-0.516-2.764-0.737c-0.264-0.063-0.528-0.126-0.794-0.184
					c-0.372-0.081-0.746-0.159-1.121-0.231c-0.354-0.069-0.71-0.13-1.067-0.191c-0.311-0.053-0.62-0.11-0.932-0.157
					c-0.643-0.097-1.292-0.178-1.943-0.248c-0.245-0.026-0.491-0.046-0.737-0.069c-0.504-0.047-1.011-0.086-1.518-0.116
					c-0.228-0.014-0.455-0.028-0.683-0.038c-0.723-0.033-1.449-0.055-2.18-0.055c-26.312,0-47.718,21.406-47.718,47.718v0.917
					c0,7.602,6.163,13.765,13.765,13.765c0.252,0,0.498-0.024,0.747-0.038c0.219-0.012,0.442-0.011,0.658-0.033
					c0.267-0.027,0.526-0.078,0.788-0.12c0.192-0.031,0.388-0.049,0.577-0.087c0.286-0.059,0.564-0.141,0.844-0.217
					c0.157-0.042,0.319-0.073,0.474-0.122c0.303-0.094,0.595-0.211,0.888-0.325c0.124-0.048,0.253-0.086,0.375-0.137
					c0.312-0.131,0.612-0.285,0.911-0.438c0.095-0.049,0.196-0.089,0.29-0.14c0.331-0.18,0.649-0.379,0.963-0.584
					c0.056-0.036,0.115-0.066,0.17-0.103c0.336-0.227,0.658-0.471,0.972-0.726c0.028-0.023,0.058-0.042,0.086-0.064
					c3.066-2.525,5.021-6.349,5.021-10.631v-0.421c0-10.933,8.485-20.256,19.41-20.669c11.485-0.434,20.967,8.783,20.967,20.173v9.176
					c0,3.651,1.45,7.152,4.032,9.733l23.792,23.792c7.913,7.913,18.645,12.358,29.835,12.358h28.39h0.086
					c7.482,0,13.561-6.029,13.616-13.511C238.269,458.391,238.269,458.292,238.27,458.193z"/>
				<linearGradient id="Lazer_22_" gradientUnits="userSpaceOnUse" x1="77.2788" y1="400.4236" x2="138.9488" y2="400.4236">
					<stop  offset="0" style={{stopColor:'#32D5FF'}}/>
					<stop  offset="1" style={{stopColor:'#1527E2'}}/>
				</linearGradient>
				<path id="Lazer_9_" fill="url(#Lazer_22_)" d="M132.282,371.309c-4.416-1.353-9.1-2.086-13.952-2.086
					c-26.312,0-47.718,21.406-47.718,47.718v0.918c0,7.602,6.163,13.765,13.765,13.765c7.602,0,13.765-6.163,13.765-13.765v-0.918
					c0-1.754,0.225-3.455,0.648-5.078C100.831,392.653,114.318,376.813,132.282,371.309"/>
			</g>
			<g id="LazerZGroup" mask="url(#ZMask)">
				<linearGradient id="Lazer_18_" gradientUnits="userSpaceOnUse" x1="-33.2876" y1="420.4053" x2="508.7541" y2="420.4053">
					<stop  offset="0" style={{stopColor:'#4E7FFF'}}/>
					<stop  offset="1" style={{stopColor:'#EC0260'}}/>
				</linearGradient>
				<path id="Lazer_8_" fill="url(#Lazer_18_)" d="M334.438,455.188c0-6.031-4.892-10.919-10.923-10.915l-16.149,0.011l-69.175,0.156
					l38.995-37.79c6.474-6.275,8.469-15.728,5.086-24.083c-3.385-8.356-11.399-13.755-20.414-13.755h-59.642
					c-7.412,0-13.789,5.696-14.138,13.099c-0.372,7.905,5.926,14.431,13.749,14.431h46.438l-39.026,37.821
					c-2.98,2.888-4.996,6.452-5.991,10.268c0,0.001,0.002,0.01,0.002,0.01c-1.646,5.94-0.131,11.207,0.909,13.774
					c3.385,8.356,11.398,13.755,20.413,13.755h-0.006L224.534,472h0.032h0.051h96.514c0.821,0,1.622-0.085,2.403-0.224l0,0
					c6.024-0.006,10.904-4.891,10.904-10.915V455.188z"/>
				<linearGradient id="Lazer_19_" gradientUnits="userSpaceOnUse" x1="188.0626" y1="386.7299" x2="283.8932" y2="386.7299">
					<stop  offset="0" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_7_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_19_)" d="M201.827,396.371h46.438h13.593c6.833,0,13.086,3.106,17.189,8.247
					c4.957-6.173,6.257-14.534,3.224-22.021c-3.385-8.356-11.398-13.755-20.413-13.755h-60.03c-7.602,0-13.765,6.162-13.765,13.764
					C188.063,390.208,194.225,396.371,201.827,396.371"/>
				<linearGradient id="Lazer_20_" gradientUnits="userSpaceOnUse" x1="202.5369" y1="454.1119" x2="298.3676" y2="454.1119">
					<stop  offset="0" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_6_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_20_)" d="M284.603,444.47h-46.438h-13.593c-6.833,0-13.086-3.107-17.188-8.247
					c-4.958,6.173-6.257,14.534-3.225,22.021c3.385,8.356,11.398,13.755,20.413,13.755h60.031c7.602,0,13.765-6.163,13.765-13.765
					S292.205,444.47,284.603,444.47"/>
			</g>
			<g id="LazerERGroup" mask="url(#ERMask)">
				<linearGradient id="Lazer_13_" gradientUnits="userSpaceOnUse" x1="-36.6971" y1="420.2952" x2="508.9196" y2="420.2952">
					<stop  offset="0" style={{stopColor:'#4E7FFF'}}/>
					<stop  offset="1" style={{stopColor:'#EC0260'}}/>
				</linearGradient>
				<path id="Lazer_5_" fill="url(#Lazer_13_)" d="M468.431,368.825h-30.535c-13.933,0-25.27,11.441-25.27,25.505v43.532
					c0,3.425-2.76,6.211-6.185,6.211l-72.004,0.164v-9.845h29.548c17.797,0,32.276-14.614,32.276-32.577s-14.479-32.576-32.276-32.576
					h-24.548c-17.797,0-32.276,14.614-32.276,32.576v21.772c0.047,0.229,0.043,0.298,0,0.25v15.709v3.852v0.859h0.021
					c0.445,14.988,12.451,27.088,27.307,27.509l71.982-0.163c18.433,0,33.429-15.136,33.429-33.741v-41.507h28.529
					c7.33,0,13.293,6.019,13.293,13.417v3.101c0,7.602,6.106,13.764,13.638,13.764c7.531,0,13.638-6.162,13.638-13.764v-3.101
					C509,387.194,490.801,368.825,468.431,368.825z M334.438,401.814c0-2.783,2.243-5.047,5-5.047h24.548c2.757,0,5,2.264,5,5.047
					s-2.243,5.047-5,5.047h-29.548V401.814z"/>
				<linearGradient id="Lazer_14_" gradientUnits="userSpaceOnUse" x1="468.4313" y1="411.9087" x2="508.9207" y2="411.9087">
					<stop  offset="0" style={{stopColor:'#92A5E2'}}/>
					<stop  offset="1" style={{stopColor:'#68E6CF'}}/>
				</linearGradient>
				<path id="Lazer_4_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_14_)" d="M508.842,413.285v-3.1c0-7.398-5.963-13.417-13.293-13.417h-27.117
					c7.33,0,13.293,6.019,13.293,13.417v3.1c0,7.602,6.106,13.765,13.638,13.765c7.041,0,12.834-5.388,13.558-12.301
					C508.87,414.268,508.842,413.78,508.842,413.285"/>
				<linearGradient id="Lazer_15_" gradientUnits="userSpaceOnUse" x1="307.1628" y1="393.5538" x2="365.3483" y2="393.5538">
					<stop  offset="0" style={{stopColor:'#7BEEB7'}}/>
					<stop  offset="0.0616" style={{stopColor:'#7DE8C2'}}/>
					<stop  offset="0.2046" style={{stopColor:'#80DDD5'}}/>
					<stop  offset="0.3512" style={{stopColor:'#81D6E0'}}/>
					<stop  offset="0.5056" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="0.6414" style={{stopColor:'#81D7DF'}}/>
					<stop  offset="0.7962" style={{stopColor:'#7FDFD2'}}/>
					<stop  offset="0.9597" style={{stopColor:'#7BECBB'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_3_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_15_)" d="M320.513,406.983h13.921v-5.386c0-17.38,13.747-31.596,30.914-32.315
					c-0.453-0.019-0.905-0.034-1.362-0.034h-24.548c-17.798,0-32.276,14.512-32.276,32.349v16.262
					C308.449,411.65,313.937,406.983,320.513,406.983"/>
				<linearGradient id="Lazer_16_" gradientUnits="userSpaceOnUse" x1="307.1628" y1="447.5837" x2="402.2065" y2="447.5837">
					<stop  offset="0" style={{stopColor:'#7BEEB7'}}/>
					<stop  offset="0.0616" style={{stopColor:'#7DE8C2'}}/>
					<stop  offset="0.2046" style={{stopColor:'#80DDD5'}}/>
					<stop  offset="0.3512" style={{stopColor:'#81D6E0'}}/>
					<stop  offset="0.5056" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="0.6414" style={{stopColor:'#81D7DF'}}/>
					<stop  offset="0.7962" style={{stopColor:'#7FDFD2'}}/>
					<stop  offset="0.9597" style={{stopColor:'#7BECBB'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_2_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_16_)" d="M388.569,444.154h-53.28c-0.469,0-0.85-0.385-0.85-0.859v-3.852h-0.004v-5.007
					h-13.921c-6.577,0-12.064-4.7-13.351-10.953v15.959v3.852v0.859h0.021c0.453,15.256,12.882,27.529,28.104,27.529h53.28
					c7.532,0,13.638-6.162,13.638-13.764C402.206,450.317,396.101,444.154,388.569,444.154"/>
				<linearGradient id="Lazer_17_" gradientUnits="userSpaceOnUse" x1="406.6635" y1="406.8615" x2="464.7928" y2="406.8615">
					<stop  offset="0" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_1_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_17_)" d="M406.664,444.659c3.425-0.02,6.168-2.823,6.148-6.248l-0.255-43.531
					c-0.083-14.063,11.186-25.572,25.119-25.655l27.117-0.162c-13.933,0.083-25.202,11.592-25.119,25.655l0.255,43.531
					c0.02,3.425-2.724,6.227-6.148,6.248L406.664,444.659z"/>
			</g>
			<g id="LazerLGroup" mask="url(#LMask)">
				<linearGradient id="Lazer_23_" gradientUnits="userSpaceOnUse" x1="-32.9336" y1="396.2939" x2="513.7849" y2="396.2939">
					<stop  offset="0" style={{stopColor:'#4E7FFF'}}/>
					<stop  offset="1" style={{stopColor:'#EC0260'}}/>
				</linearGradient>
				<path id="Lazer_12_" fill="url(#Lazer_23_)" d="M-20.235,320.588c-7.602,0-13.765,6.163-13.765,13.765s6.163,13.765,13.765,13.765
					h43.129v72.285c0,28.451,23.147,51.597,51.597,51.597h42.003c7.602,0,13.765-6.163,13.765-13.765s-6.163-13.765-13.765-13.765
					H74.492c-13.271,0-24.068-10.796-24.068-24.068v-86.05c0-7.602-6.163-13.765-13.765-13.765H-20.235z"/>
				<linearGradient id="Lazer_24_" gradientUnits="userSpaceOnUse" x1="22.8946" y1="410.0479" x2="72.8621" y2="410.0479">
					<stop  offset="0" style={{stopColor:'#82D4E4'}}/>
					<stop  offset="1" style={{stopColor:'#7AF0B4'}}/>
				</linearGradient>
				<path id="Lazer_11_" style={{mixBlendMode:'overlay'}} fill="url(#Lazer_24_)" d="M50.424,447.952v-27.549v-58.501c0-7.602-6.162-13.764-13.765-13.764H22.895v72.265
					c0,27.905,22.27,50.69,49.967,51.556C60.349,471.117,50.424,460.675,50.424,447.952"/>
			</g>
			<defs>
				<mask id="ERMask">
					<path
						id="ERMaskPath"
						fill="none"
						stroke="#FFF"
						strokeWidth="29"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M320.333,420.979h44c9.757,0,17.667-7.91,17.667-17.667v-2.646c0-9.757-7.91-17.667-17.667-17.667H338
						c-9.757,0-17.667,7.91-17.667,17.667v39c0,9.757,7.91,17.667,17.667,17.667h71.333c9.021,0,16.333-7.313,16.333-16.333v-43.917
						c0-8.33,6.753-15.083,15.083-15.083h27.75c14.636,0,26.5,11.864,26.5,26.5v7.167"
					/>
				</mask>
				<mask id="ZMask">
					<path
						id="ZMaskPath"
						fill="none"
						stroke="#FFF"
						strokeWidth="30"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M199.333,383l61.818-1c7.799,0,11.705,9.429,6.19,14.944l-48.689,47.071c-5.515,5.515-1.609,14.944,6.19,14.944H330.5"
					/>
				</mask>
				<mask id="AMask">
					<path
						id="AMaskPath"
						fill="none"
						stroke="#FFF"
						strokeWidth="30"
						strokeLinecap="round"
						strokeMiterlimit="10"
						/* strokeDasharray="228"
						strokeDashoffset="-228" */
						/* d="M248.306,458.653h-54.125c-6.163,0-12.073-2.448-16.431-6.806L150,424l1.667-8.667c0-17.949-16.217-32.833-34.167-32.833h0
								C99.551,382.5,85,396.051,85,414v3.583" */
						d="M248.3,459.8h-54.1c-6.2,0-12.1-2.4-16.4-6.8l-23.2-23.7c-1.2-1.2-1.9-2.9-2-4.6c-0.9-26.9-15.8-41.1-35.2-41.1l0,0
							c-17.9,0-32.5,13.6-32.5,31.5v3.6"
					/>
				</mask>
				<mask id="LMask">
					<path
						id="LMaskPath"
						fill="none"
						stroke="#FFF"
						strokeWidth="30"
						strokeLinecap="round"
						strokeMiterlimit="10"
						/* strokeDasharray="245"
						strokeDashoffset="245" */
						d="M-20.25,335.5H35c1.381,0,2.5,1.119,2.5,2.5v85c0,19.882,16.118,36,36,36h43.25"
					/>
				</mask>
			</defs>
		</svg>
	)
}

export default LazerLogo





