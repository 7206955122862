import React, { useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import {
  Box,
  Heading,
  SimpleGrid,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container
} from "@chakra-ui/react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ReactMarkdown from 'react-markdown'
import LazerLogo from "../images/LazerLogo.svg.js"
import SVGStep1 from "../images/step1.inline.svg"
import SVGStep2 from "../images/step2.inline.svg"
import SVGStep3 from "../images/step3.inline.svg"
import { breakpoints } from '../theme/theme'

const IndexPage = ({ data, location }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalOpen, setModalOpen] = useState(data.strapiLazer.service.map(() => {return false}))
  const handleModal = (m, update) => {
    let modalOpenUpdated = [...modalOpen]
    modalOpenUpdated.splice(m, 1, update)
    setModalOpen(modalOpenUpdated)
  }
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/ecc701fd-259c-496b-b01a-7c7d3aa8eb39",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };  

  const faqContent = [
    {
      faqQuestion: 'Question 01',
      faqAnswer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      faqQuestion: 'Question 02',
      faqAnswer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      faqQuestion: 'Question 03',
      faqAnswer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
  ]

  return (
    <Layout>
      <SEO title="Lazer by Alopex" />
      <Box
        maxWidth="960px"
        m="260px auto"
        padding="0 20px"
        >
        <LazerLogo style={{maxWidth: '700px', margin: '0 auto 40px auto'}} />
        <Box as="p" textAlign="center" fontSize="xl" textStyle="gradText0" display="table" m="0 auto">
          Dominate search results
        </Box>
      </Box>
      <Box
        maxWidth="960px"
        m="0 auto 200px auto"
        padding="0 20px"
      >

        {/* WHAT */}
        <Box className="sectionWhat">
          <Heading as="h6" textStyle="h6moo" mb="20px">What</Heading>
          <Box textStyle="description" maxWidth="700px">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.whatContent} />
          </Box>
          <SimpleGrid columns={[1, 1, 3]} gap="30px">
            <Box>
              <Flex
                position="relative"
                mb="20px"
                pb="10px"
                _after={{
                  content: '""',
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundImage: 'linear-gradient(to right, #D989EF, #182DF5)',
                  boxShadow: '0 0 5px rgb(217 137 239 / 0.5)'
                }}
              >
                <Heading as="h2" className="glow1" color="#B251DA" fontSize="72px" mr="20px">1</Heading>
                <SVGStep1 style={{maxWidth: '100px', width: '100%', height: 'auto'}} />
              </Flex>
              <Box as="p">
              {data.strapiLazer.feature01}
              </Box>
            </Box>
            <Box>
              <Flex
                position="relative"
                mb="20px"
                pb="10px"
                _after={{
                  content: '""',
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundImage: 'linear-gradient(to right, #D989EF, #182DF5)',
                  boxShadow: '0 0 5px rgb(217 137 239 / 0.5)'
                }}
              >
                <Heading as="h2" className="glow2" color="#D324CA" fontSize="72px" mr="20px">2</Heading>
                <SVGStep2 style={{maxWidth: '100px', width: '100%', height: 'auto'}} />
              </Flex>
              <Box as="p">
              {data.strapiLazer.feature02}
              </Box>
            </Box>
            <Box>
              <Flex
                position="relative"
                mb="20px"
                pb="10px"
                _after={{
                  content: '""',
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundImage: 'linear-gradient(to right, #D989EF, #182DF5)',
                  boxShadow: '0 0 5px rgb(217 137 239 / 0.5)'
                }}
              >
                <Heading as="h2" className="glow3" color="#D41679" fontSize="72px" mr="20px">3</Heading>
                <SVGStep3 style={{maxWidth: '100px', width: '100%', height: 'auto'}} />
              </Flex>
              <Box as="p">
              {data.strapiLazer.feature03}
              </Box>
            </Box>
          </SimpleGrid>
        </Box>

        {/* WHO */}
        <Box className="sectionWho">
          <Heading as="h6" textStyle="h6" mb="20px">Who</Heading>
          <Box textStyle="description">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.whoContent} />
          </Box>
          <Box>
            {data.strapiLazer.service.map((serv, i) => {
              return (
                <Box key={`modals-4-life-${i}`} display="inline-block">
                  <Box as="a" className="gBoxBorder" onClick={() => { handleModal(i, true) }}>{serv.title}</Box>
                  <Modal
                    isOpen={modalOpen[i]}
                    onClose={() => { handleModal(i, false) }}
                    size="xl"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <Heading as="h4" textStyle="h4">
                          {serv.title}
                        </Heading>
                      </ModalHeader>
                      <ModalCloseButton borderRadius="0" outline="none" _focus={{boxShadow: "none"}} onClick={() => { handleModal(i, false) }} />
                      <ModalBody pb={6}>
                        {serv.description}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>
              )
            })}
          </Box>
        </Box>

        {/* PRICING */}
        <Box className="sectionPricing">
          <Heading as="h6" textStyle="h6" mb="20px">Pricing</Heading>
          <Box textStyle="description">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.pricingContentTop} />
          </Box>
          <SimpleGrid columns={[1, 1, 3]} gap="30px" mb="40px">
            <Box
              backgroundImage="linear-gradient(to bottom right, rgba(24, 45, 245, 0.2), rgba(178, 81, 218, 0.4))"
              minHeight="220px"
              padding="30px"
              borderRadius="20px"
              border="3px solid rgba(157, 0, 255, 0.6)"
              textAlign="center"
              boxShadow="0 0 10px #E30DB2"
              position="relative"
              zIndex="20"
              _after={{
                content: '"+"',
                position: 'absolute',
                top: ['100%', '100%', '50%'],
                left: ['50%', '50%', '100%'],
                transform: ['translate(-50%, -20%)', 'translate(-50%, -20%)', 'translate(-22%, -50%)'],
                fontSize: '90px',
                lineHeight: '60px',
                height: '60px',
                width: '60px',
                color: '#5CD5F4',
                textAlign: 'center',
                textShadow: '0 0 20px #5CD5F4'
              }}
            >
              <Heading as="h3" textStyle="h3" mb="20px" color="#B251DA" textShadow="0 0 10px #B251DA">{data.strapiLazer.box[0].title}</Heading>
              <Box as="p" p="0 40px" mb="0">{data.strapiLazer.box[0].blurb}</Box>
            </Box>
            <Box
              backgroundImage="linear-gradient(to bottom right, rgba(178, 81, 218, 0.2), rgba(211, 36, 202, 0.4))"
              minHeight="220px"
              padding="30px"
              borderRadius="20px"
              border="3px solid rgba(229, 15, 255, 0.6)"
              textAlign="center"
              boxShadow="0 0 10px #E30DB2"
              position="relative"
              zIndex="10"
              _after={{
                content: '"+"',
                position: 'absolute',
                top: ['100%', '100%', '50%'],
                left: ['50%', '50%', '100%'],
                transform: ['translate(-50%, -20%)', 'translate(-50%, -20%)', 'translate(-22%, -50%)'],
                fontSize: '90px',
                fontWeight: 'normal',
                lineHeight: '60px',
                height: '60px',
                width: '60px',
                color: '#5CD5F4',
                textAlign: 'center',
                textShadow: '0 0 20px #5CD5F4'
              }}
            >
              <Heading textStyle="h3" mb="20px" color="#D324CA" textShadow="0 0 10px #B251DA">{data.strapiLazer.box[1].title}</Heading>
              <Box as="p" p="0 30px" mb="0">{data.strapiLazer.box[1].blurb}</Box>
            </Box>
            <Box
              backgroundImage="linear-gradient(to bottom right, rgba(211, 36, 202, 0.4), rgba(212, 22, 121, 0.6))"
              minHeight="220px"
              padding="30px"
              borderRadius="20px"
              border="3px solid rgba(255, 24, 216, 0.6)"
              textAlign="center"
              boxShadow="0 0 10px #E30DB2"
            >
              <Heading as="h3" textStyle="h3" mb="20px" color="#D41679" textShadow="0 0 10px #B251DA">{data.strapiLazer.box[2].title}</Heading>
              <Box as="p" p="0 30px" mb="0">{data.strapiLazer.box[2].blurb}</Box>
            </Box>
          </SimpleGrid>
          <Box textStyle="description" color="brand.blue">
            <Box as="span" textStyle="gradText2">
              <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.pricingContentBottom} />
            </Box>
          </Box>
        </Box>

        {/* GUARANTEE */}
        <Box className="sectionGuarantee">
          <Heading as="h6" textStyle="h6" mb="20px">Guarantee</Heading>
          <Box textStyle="description" mb="20px">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.guaranteeContent} />
          </Box>
        </Box>

        {/* REPORTING */}
        <Box className="sectionReporting">
          <Heading as="h6" textStyle="h6" mb="20px">Reporting</Heading>
          <Box textStyle="description" mb="20px">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.reportingContent} />
          </Box>
        </Box>

        {/* WHO IS ALOPEX */}
        <Box className="sectionAlopex">
          <Heading as="h6" textStyle="h6" mb="20px">Who is Alopex</Heading>
          <Box textStyle="description">
            <Box as="span" textStyle="gradText1">
              <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.alopexContent} />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* FOOTER */}
      <Box
        as="footer"
        backgroundImage="linear-gradient(to right, rgba(212, 22, 121, 0.55), rgba(178, 81, 218, 0.55), rgba(0, 75, 255, 0.55), rgba(92, 213, 244, 0.55))"
        borderTop="3px solid #5CD5F4"
        borderBottom="3px solid #5CD5F4"
        boxShadow="3px solid rgba(92, 213, 244, 0.5)"
        p="0 20px"
        mb="200px"
      >
        <Box maxWidth="960px" m="0 auto" p="100px 0" color="brand.lightblue">
          <Box mb="60px">
            <ReactMarkdown allowDangerousHtml={true} source={data.strapiLazer.contactContent} />
          </Box>
          <form onSubmit={handleOnSubmit}>
            <SimpleGrid columns={[1, 1, 2]} gap="30px">
              <Input type="text" name="Name" placeholder="Name" isRequired />
              <Input type="text" name="Company Name" placeholder="Company Name" />
              <Input type="text" name="Email" placeholder="Email" isRequired />
              <Input type="text" name="Phone" placeholder="Phone" />
              <Input type="text" name="Industry" placeholder="Industry" />
              <Input type="submit" value="SUBMIT" />
            </SimpleGrid>
          </form>
        </Box>
      </Box>

      {/* FAQ-U */}
      <Container maxWidth={breakpoints.lg} mb="200px">
        <Heading as="h2" mb="20px">Frequently Asked Questions</Heading>
        <Accordion borderRight="1px solid rgba(255, 255, 255, 0.16)" borderLeft="1px solid rgba(255, 255, 255, 0.16)" allowToggle>
          {faqContent.map((faq, i) => {
            return (
              <AccordionItem>
                  <AccordionButton padding="15px 20px" _focus={{boxShadow: 'none'}}>
                    <Box flex="1" textAlign="left">
                      <Heading as="h5" textStyle="h5" fontSize="26px" fontWeight="normal">
                        {faq.faqQuestion}
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel padding="20px 20px 30px" fontSize="18px">
                  {faq.faqAnswer}
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query lazer {
  strapiLazer {
    whatContent
    feature01
    feature02
    feature03
    whoContent
    service {
      title
      description
    }
    pricingContentTop
    box {
      title
      blurb
    }
    pricingContentBottom
    guaranteeContent
    reportingContent
    alopexContent
    contactContent
  }
}
`

export default IndexPage
